const form = [
  {
    type: 'image',
    name: 'image',
    label: 'Image',
    placeholder: 'Image',
    component: 'el-input',
    required: true,
  },
];

const model = {
  image: '',
};

const rules = {
  image: [
    {
      type: 'required',
      message: 'Please upload an image',
    },
  ],
};

export { form, model, rules };